import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Col, Container, Row } from "reactstrap"
//Import Icons
import FeatherIcon from "feather-icons-react"
import { GatsbyImage } from "gatsby-plugin-image"

const Footer = () => {

  const { logo, menu } = useStaticQuery(query)

  const menu1 = []
  menu.data.nav.map((item1, key1) => {
    if (item1.primary.sub_menu.document !== null) {
      let menu2 = []
      item1.primary.sub_menu.document.data.nav.map((item2, key2) => {
        menu2.push({
          id: key2,
          title: item2.primary.label,
          link: item2.primary.link.url
        })
      })
      if (menu2.length > 0) {
        menu1.push({ id: key1, title: item1.primary.label, link: "/#", child: menu2 })
      } else {
        menu1.push({ id: key1, title: item1.primary.label, link: "/#", child: [] })
      }
    } else {
      menu1.push({ id: key1, title: item1.primary.label, link: item1.primary.link.url, child: [] })
    }
  })

  return <>
    <div className="position-relative">
      <div className="shape overflow-hidden text-footer">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <footer className={"footer"}>
      <Container>
        <Row>
          <Col
            lg="4"
            xs="12"
            className="mb-0 mb-md-4 pb-0 pb-md-2"
            name="footercolumn"
          >
            <Link to="/" className="logo-footer">
              <GatsbyImage
                image={logo.childImageSharp.gatsbyImageData}
                alt="Logiciel de comptabilite pour entrepreneurs"/>
            </Link>
            <p className={"mt-4"}>
              Bizyness est la solution indispensable pour gérer votre
              entreprise. Les nombreuses
              fonctionnalités vous permettent de vous libérer du temps et de vous concentrer
              sur l’essentiel : vendre vos services et produits !
            </p>
            <ul className="list-unstyled social-icon social mb-0 mt-4">
              <li className="list-inline-item mr-1">
                <a
                  href="https://www.facebook.com/bizynessAE/"
                  className="rounded" target="_blank"
                >
                  <i>
                    <FeatherIcon
                      icon="facebook"
                      className="fea icon-sm fea-social"
                    />
                  </i>
                </a>
              </li>
              <li className="list-inline-item mr-1">
                <a href="https://www.instagram.com/bizyness_fr/" className="rounded" target="_blank">
                  <i>
                    <FeatherIcon
                      icon="instagram"
                      className="fea icon-sm fea-social"
                    />
                  </i>
                </a>
              </li>
            </ul>
          </Col>


          {menu1.map((item, key) =>
            <Col
              lg={key+2}
              md="4"
              xs="12"
              className="mt-4 mt-sm-0 pt-2 pt-sm-0"
              name="footercolumn"
            >
              <div className={"text-light footer-head"}>{item.title}</div>
              <ul className="list-unstyled footer-list mt-4">
                {item.child.map((sublink, subkey) => (
                  <li key={subkey}>
                    <Link to={sublink.link} className={"text-foot"}>
                      <i className="mdi mdi-chevron-right mr-1"></i>{" "}
                      {sublink.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </Col>
          )}


          <Col
            lg="3"
            md="4"
            xs="12"
            className="mt-4 mt-sm-0 pt-2 pt-sm-0"
            name="footercolumn"
          >
            <div className="text-light footer-head">Newsletter</div>
            <p className="mt-4">
              Abonnez-vous pour recevoir l'actualité des entrepreneurs.
            </p>
            <script src="https://f.convertkit.com/ckjs/ck.5.js"></script>
            <form
              action="https://app.convertkit.com/forms/1469550/subscriptions"
              className="seva-form formkit-form"
              method="post"
              data-sv-form="1469550"
              data-uid="2ea2fe3044"
              data-format="inline"
              data-version="5"
              data-options='{"settings":{"after_subscribe":{"action":"message","success_message":"Merci pour votre inscription","redirect_url":""},"analytics":{"google":null,"facebook":null,"segment":null,"pinterest":null},"modal":{"trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"powered_by":{"show":false,"url":"https://convertkit.com?utm_source=dynamic&amp;utm_medium=referral&amp;utm_campaign=poweredby&amp;utm_content=form"},"recaptcha":{"enabled":false},"return_visitor":{"action":"show","custom_content":""},"slide_in":{"display_in":"bottom_right","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15},"sticky_bar":{"display_in":"top","trigger":"timer","scroll_percentage":null,"timer":5,"devices":"all","show_once_every":15}},"version":"5"}'
              min-width="400 500 600 700 800"
            >
              <Row>
                <Col lg="12">
                  <div
                    data-style="clean"
                    className="foot-subscribe form-group position-relative"
                  >
                    <ul
                      className="formkit-alert formkit-alert-error"
                      data-element="errors"
                      data-group="alert"
                    ></ul>
                    <div
                      data-element="fields"
                      data-stacked="false"
                      className="seva-fields formkit-fields"
                    >
                      <div className="formkit-field">
                        <label htmlFor="email">
                          Indiquez votre email{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <i>
                          <FeatherIcon
                            icon="mail"
                            className="fea icon-sm icons"
                          />
                        </i>
                        <input
                          className="formkit-input form-control pl-5 rounded"
                          name="email_address"
                          placeholder="Votre email :"
                          required
                          type="email" id="email"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="12">
                  <button
                    data-element="submit"
                    className="formkit-submit formkit-submit btn btn-soft-primary btn-block"
                  >
                    <div className="formkit-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <span>S'abonner</span>
                  </button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      </Container>
    </footer>
    <footer className="footer footer-bar">
      <Container className="text-center">
        <Row className="align-items-center">
          <Col sm="6">
            <div className="text-sm-left">
              <p className="mb-0">
                © {new Date().getFullYear()} Bizyness. Développé avec{" "}
                <i className="mdi mdi-heart text-danger"></i> en
                Haute-Savoie.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  </>
}

export default Footer

const query = graphql`
  query Footer {
    logo: file(relativePath: {eq: "logo-light.png"}) {
      childImageSharp {
        gatsbyImageData(height: 24, quality: 90, placeholder: NONE, layout: FIXED)
      }
    }
    menu: prismicMenu(data: {name: {eq: "Footer"}}) {
      data {
        nav {
          ... on PrismicMenuDataNavNavItem {
            id
            primary {
              label
              link {
                url
              }
              sub_menu {
                document {
                  ... on PrismicSubMenu {
                    id
                    data {
                      name
                      nav {
                        ... on PrismicSubMenuDataNavNavItem {
                          id
                          items {
                            sub_nav_label
                            sub_nav_link {
                              url
                            }
                            isnew
                          }
                          primary {
                            label
                            link {
                              url
                            }
                            isnew
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicPage {
                    id
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
