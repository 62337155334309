export function setAffiliateId(affiliate) {
  var cookieId
  if (affiliate) {
    cookieId = affiliate
  } else {
    var sPageURL = decodeURIComponent(window.location.search.substring(1))
    var sURLVariables = sPageURL.split("&")
    var sParameterName
    var i
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=")
      if (sParameterName[0] === "_a") {
        cookieId = sParameterName[1]
      }
    }
  }
  console.log("affiliate: " + cookieId)
  if (cookieId) {
    var date = new Date()
    //Set cookie lifetime to 30 days
    date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000))
    var expire = ";expires=" + date.toGMTString()
    document.cookie = "affiliate=" + cookieId + expire + ";domain=.bizyness.fr;path=/"
  }
}

export function getAffiliateId() {
  if (typeof document !== "undefined") {
    var b = document.cookie.match("(^|;)\\s*affiliate\\s*=\\s*([^;]+)")
    return b ? b.pop() : ""
  }
}


export function setTags(tags) {
  var cookieId
  if (tags) {
    cookieId = tags
  } else {
    var sPageURL = decodeURIComponent(window.location.search.substring(1))
    var sURLVariables = sPageURL.split("&")
    var sParameterName
    var i
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=")
      if (sParameterName[0] === "_t") {
        cookieId = sParameterName[1]
      }
    }
  }
  console.log("tags: " + cookieId)
  if (cookieId) {
    var date = new Date()
    date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000))
    var expire = ";expires=" + date.toGMTString()
    document.cookie = "tags=" + cookieId + expire + ";domain=.bizyness.fr;path=/"
  }
}

export function getTags() {
  if (typeof document !== "undefined") {
    var b = document.cookie.match("(^|;)\\s*tags\\s*=\\s*([^;]+)")
    return b ? b.pop() : ""
  }
}

export function setCoupons(coupons) {
  var cookieId
  if (coupons) {
    cookieId = coupons
  } else {
    var sPageURL = decodeURIComponent(window.location.search.substring(1))
    var sURLVariables = sPageURL.split("&")
    var sParameterName
    var i
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=")
      if (sParameterName[0] === "_c") {
        cookieId = sParameterName[1]
      }
    }
  }
  console.log("coupons: " + cookieId)
  if (cookieId) {
    var date = new Date()
    date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000))
    var expire = ";expires=" + date.toGMTString()
    document.cookie = "coupons=" + cookieId + expire + ";domain=.bizyness.fr;path=/"
  }
}

export function getCoupons() {
  if (typeof document !== "undefined") {
    var b = document.cookie.match("(^|;)\\s*coupons\\s*=\\s*([^;]+)")
    return b ? b.pop() : ""
  }
}

export function getTrialDays() {
  var trial = "15"
  if (getAffiliateId() !== "") {
    trial = "30"
  }
  if (getCoupons() === "trial60") {
    trial = "60"
  }
  return trial
}
